<template>
  <div :class="route.name" class="site-body">
      <GlobalNotificationBanner />
      <GlobalHeader />
      <div class="grid-container relative pb-8 mt-12">
        <div class="grid-x align-center">
          <div class="cell small-12 medium-10 large-8">
            <AccountUiBack v-if="$route.name != 'account'" />
            <AccountUiInitials :initials="userInitials" />
            <NuxtPage :email="email" :userName="userName" />
            <AccountUiPageBottom />
          </div>
        </div>
      </div>
      <GlobalFooter />
  </div>
</template>

<script setup>
const supabase = useSupabaseClient();
const user = useSupabaseUser();
const route = useRoute();

const userName = ref(null);
const email = ref(null);

// Fetch user data
const fetchUser = async () => {
  if (user.value) {
    const { data, error } = await supabase
      .from("profiles")
      .select("first_name, last_name, email")
      .eq("id", user.value.id)
      .single();

    if (data && !error) {
      userName.value = `${data.first_name} ${data.last_name}`;
      email.value = data.email;
    } else {
      console.error("Error fetching user data:", error);
    }
  }
};

await fetchUser();

// Compute initials
const userInitials = computed(() => {
  if (!userName.value) return "";
  const names = userName.value.split(" ");
  return names.map(n => n.charAt(0)).join("").toUpperCase(); // Extract first letter of each name
});

if (process.env.NUXT_ENV === "staging") {
  useHead({
    script: [
      {
        innerHTML: `(function(k,s) {
            s=document.createElement('script');s.module=true;s.async=true;
            s.src="https://cdn.feedbucket.app/assets/feedbucket.js";
            s.dataset.feedbucket=k;document.head.appendChild(s);
        })('p2XufyybCZJEXvwXMRdY')`,
      },
    ],
  });
}

onMounted(() => {
  useSagePay()
})

</script>
